import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import { action, computed, observable } from 'mobx';
import {
  Intervention,
  InterventionDTO,
  InterventionResourceUpdate,
  InterventionResourceOrderUpdate,
} from '@shared/models/interventions';
import InterventionsService from '@shared/services/interventions';
import {
  InterventionContent,
  InterventionUploadResourceData,
} from '@shared/models/interventioncontent';

@injectable()
export default class InterventionsStore extends BaseStore<
  InterventionDTO,
  Intervention,
  InterventionsService
> {
  static diToken = Symbol('interventions-store');
  @observable private _therapeuticInterventions: Intervention[];
  @observable private _interventionContent: InterventionContent[];
  @observable private _treatmentOptionContent: { number: InterventionContent[] };

  constructor() {
    super({
      service: container.get<InterventionsService>(InterventionsService.diToken),
    });

    this.loading = {
      list: false,
      item: false,
    };
  }

  @action fetchTherapeuticInterventions = async () => {
    const therapeuticInterventions = await this.service.getTerapeuticInterventions();
    this._therapeuticInterventions = therapeuticInterventions;

    return therapeuticInterventions;
  };

  @computed get therapeuticInterventions(): Intervention[] {
    return this._therapeuticInterventions;
  }

  @computed get treatmentOptionContent(): { number: InterventionContent[] } {
    return this._treatmentOptionContent;
  }

  @computed get interventionContent(): InterventionContent[] {
    return this._interventionContent;
  }

  @computed get therapeuticInterventionsMenuItems() {
    if (this._therapeuticInterventions) {
      return this._therapeuticInterventions.map(({ id, name }) => ({
        id,
        title: name,
      }));
    }

    return [];
  }

  @action getTreatmentOptionContentById = async (
    interventionId: number,
    treatmentOptionId: number,
  ) => {
    const treatmentOptionContent = await this.service.getTreatmentOptionContentById(
      interventionId,
      treatmentOptionId,
    );

    this._treatmentOptionContent = {
      ...this._treatmentOptionContent,
      [treatmentOptionId]: treatmentOptionContent,
    };

    return treatmentOptionContent;
  };

  @action getInterventionContentById = async (interventionId: number) => {
    const interventionContent = await this.service.getInterventionContentById(interventionId);

    this._interventionContent = interventionContent;

    return interventionContent;
  };

  @action updateInterventionContentById = (
    contentResourceId: number,
    { title, url, treatmentOptionIds }: InterventionResourceUpdate,
  ) => {
    this._interventionContent = this._interventionContent.map(content => {
      if (content.contentResourceId === contentResourceId) {
        return {
          ...content,
          ...(title ? { title } : {}),
          ...(url ? { linkMetadata: { url } } : {}),
          ...(treatmentOptionIds
            ? {
                treatmentOptions: content.treatmentOptions.filter(opt =>
                  treatmentOptionIds.includes(opt.id),
                ),
              }
            : {}),
        } as InterventionContent;
      }

      return content;
    });
  };

  @action removeResource = async (
    interventionId: number,
    treatmentOptionId: number,
    resourceId: number,
  ) => {
    return await this.service.removeResource(interventionId, treatmentOptionId, resourceId);
  };

  @action removeResourceByInterventionId = async (interventionId: number, resourceId: number) => {
    await this.service.removeResourceByInterventionId(interventionId, resourceId);
    this._interventionContent = this._interventionContent.filter(
      content => content.contentResourceId !== resourceId,
    );
  };

  @action addResourceByTreatmentOption = async (
    interventionId: number,
    treatmentOptionId: number,
    data: InterventionUploadResourceData,
  ) => {
    return this.service.addResourceByTreatmentOption(interventionId, treatmentOptionId, data);
  };

  @action addResourceByInterventionId = async (
    interventionId: number,
    data: InterventionUploadResourceData,
  ) => {
    const response = await this.service.addResourceByInterventionId(interventionId, data);

    this._interventionContent = [...this.interventionContent, response.data];
  };

  @action updateResource = async (
    interventionId: number,
    contentResourceId: number,
    data: InterventionResourceUpdate,
  ) => {
    return this.service.updateResource(interventionId, contentResourceId, data);
  };

  @action updateResourceOrder = async (
    interventionId: number,
    treatmentOptionId: number,
    data: InterventionResourceOrderUpdate[],
  ) => {
    return this.service.updateResourceOrder(interventionId, treatmentOptionId, data);
  };
}
