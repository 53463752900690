import * as React from 'react';
import styles from './Modal.styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Grid, Paper, Typography } from '@material-ui/core';
import Button from '../Button';
import MuiModal from '@material-ui/core/Modal';
import clsx from 'clsx';
import { GridJustification } from '@material-ui/core/Grid/Grid';

interface ModalProps extends WithStyles<typeof styles> {
  onClose: () => void;
  title: string;
  submitText?: string;
  cancelText?: string;
  withCancelBtn?: boolean;
  childrenAlignment?: GridJustification;
  submitAction?: () => void;
  canSubmit?: boolean;
  canCancel?: boolean;
  loading?: boolean;
  fontSizeTitle?: number;
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  classes,
  children,
  onClose,
  title,
  submitText,
  cancelText,
  submitAction,
  canSubmit = true,
  canCancel = true,
  withCancelBtn = true,
  childrenAlignment = 'center',
  loading,
  fontSizeTitle,
}) => {
  return (
    <MuiModal open={true}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.popupContent}
      >
        <Paper className={clsx(classes.paper)}>
          <Grid container spacing={2}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography
                variant="h4"
                component="h4"
                style={{ fontWeight: 500, fontSize: fontSizeTitle || 28 }}
              >
                {title}
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              justify={childrenAlignment}
              alignItems="center"
              className={classes.childContent}
            >
              {children}
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              className={classes.buttonsSection}
            >
              {withCancelBtn && (
                <Button
                  type="submit"
                  text={cancelText || 'CANCEL'}
                  variant="text"
                  onClick={onClose}
                  disabled={!canCancel}
                />
              )}
              {submitText && (
                <Button
                  type="submit"
                  text={submitText}
                  onClick={submitAction}
                  className={classes.submitButton}
                  disabled={!canSubmit}
                  loading={loading}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </MuiModal>
  );
};

export default withStyles(styles)(Modal);
