import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import { AxiosInstance } from 'axios';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import {
  Intervention,
  InterventionDTO,
  InterventionResourceUpdate,
  InterventionResourceOrderUpdate,
} from '@shared/models/interventions';
import {
  InterventionContent,
  InterventionUploadResourceData,
} from '@shared/models/interventioncontent';

@injectable()
export default class InterventionsService extends BaseService<InterventionDTO, Intervention> {
  static diToken = Symbol('interventions-service');
  private httpClient: AxiosInstance;

  constructor() {
    super({
      domainArea: 'therapeutic-interventions',
      Model: Intervention,
      collection: '',
    });

    this.httpClient = container.get<HttpClient>(HttpClient.diToken).getInstance();
  }

  async getTerapeuticInterventions() {
    const { data } = await this.httpClient.get<Intervention[]>(this.getURL(''));

    return data;
  }

  async getTreatmentOptionContentById(interventionId: number, treatmentOptionId: number) {
    const { data } = await this.httpClient.get<InterventionContent[]>(
      this.getURL(`${interventionId}/treatment-options/${treatmentOptionId}/content-resources`),
    );

    return data;
  }

  async getInterventionContentById(interventionId: number) {
    const { data } = await this.httpClient.get<InterventionContent[]>(
      this.getURL(`${interventionId}/content-resources`),
    );

    return data;
  }

  async removeResourceByInterventionId(interventionId: number, resourceId: number) {
    const { data } = await this.httpClient.delete(
      this.getURL(`${interventionId}/content-resources/${resourceId}`),
    );

    return data;
  }

  async removeResource(interventionId: number, treatmentOptionId: number, resourceId: number) {
    const { data } = await this.httpClient.delete(
      this.getURL(
        `${interventionId}/treatment-options/${treatmentOptionId}/content-resources/${resourceId}`,
      ),
    );

    return data;
  }

  async addResourceByInterventionId(interventionId: number, data: InterventionUploadResourceData) {
    return this.httpClient.post<InterventionContent>(
      this.getURL(`${interventionId}/content-resources`),
      data,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
  }

  async addResourceByTreatmentOption(
    interventionId: number,
    treatmentOptionId: number,
    data: InterventionUploadResourceData,
  ) {
    return this.httpClient.post(
      this.getURL(`${interventionId}/treatment-options/${treatmentOptionId}/content-resources`),
      data,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
  }

  async updateResource(
    interventionId: number,
    contentResourceId: number,
    payload: InterventionResourceUpdate,
  ) {
    const { data } = await this.httpClient.put(
      this.getURL(`${interventionId}/content-resources/${contentResourceId}`),
      payload,
    );

    return data;
  }

  async updateResourceOrder(
    interventionId: number,
    treatmentOptionId: number,
    payload: InterventionResourceOrderUpdate[],
  ) {
    const { data } = await this.httpClient.put(
      this.getURL(`${interventionId}/treatment-options/${treatmentOptionId}/resources/order`),
      payload,
    );

    return data;
  }
}
